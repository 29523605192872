import React, { useState } from "react";
import Moment from "moment";
import Traec from "traec";

import { BSBtnDropdown } from "traec-react/utils/bootstrap";
import { alertSuccess } from "traec-react/utils/sweetalert";
import Swal from "sweetalert2";
import { setAndShowModal } from "AppSrc/utils/modal";
import { rpName } from "storybook-dashboard/dashboard/project/reportStatus";
import { useProjectPermissions } from "../utils/hooks";

const setCommitReportingPeriod = (commit, reportPeriodId) => {
  console.log("Setting commit", commit?.toJS(), "to reporting_period", reportPeriodId);

  let fetch = new Traec.Fetch("tracker_ref_commit", "patch", {
    trackerId: commit.get("tracker"),
    refId: commit.get("ref"),
    commitId: commit.get("uid"),
  });

  let _id = reportPeriodId || null;
  let _body = {
    reporting_period: _id,
    ...(_id ? {} : { due_date: null }),
  };

  fetch.updateFetchParams({
    body: _body,
    postSuccessHook: () => {
      location.reload();
    },
  });

  fetch.dispatch();
};

const editCommitReportingPeriod = ({ projectReportingPeriods, commit, commits }) => {
  let MODAL_ID = "commonReportPeriodModal001";

  let excludeRps = new Traec.Im.Set(
    (commits || Traec.Im.List()).map((i) => i.getInPath("reporting_period.uid") || i.get("reporting_period")),
  );

  let options = projectReportingPeriods
    .toList()
    .filter((rp) => rp)
    //.filter(rp => !excludeRps.contains(rp.get("uid")))
    .sortBy((i) => i.get("startDate"))
    .map((rp, i) => (
      <option key={i} value={rp.get("uid")} disabled={excludeRps.contains(rp.get("uid"))}>
        {rpName(rp)}
      </option>
    ))
    .unshift(<option key={-1} value={""}></option>);

  let projectId = commit.get("project").substring(0, 8);
  let refId = commit.get("ref").substring(0, 8);
  let rpSettingsUrl = `/project/${projectId}/wpack/${refId}/details`;

  let currentRPId = commit.getInPath("reporting_period.uid") || commit.get("reporting_period");
  console.log("Editing commit Reporting Period", currentRPId);

  setAndShowModal(MODAL_ID, {
    title: "Edit Reporting Period",
    body: (
      <div>
        <select
          className="form-control"
          value={currentRPId || ""}
          onChange={(e) => setCommitReportingPeriod(commit, e.target.value)}
        >
          {options}
        </select>
        <p>
          You are changing the reporting period for a report already submitted. You cannot select a period that has
          already been submitted or the current reporting period. To change the current reporting period, please go to
          the settings page for this reporting package{" "}
          <a href={rpSettingsUrl} style={{ color: "#007bff" }}>
            here
          </a>
        </p>
      </div>
    ),
  });
};

const moveCommitFetch = (trackerId, commitId, toRefId) => {
  let fetch = new Traec.Fetch("tracker_dispatch", "post", { trackerId });

  let payload = {
    commit_id: commitId,
    to_ref_id: toRefId,
  };

  let formData = new FormData();
  formData.append("type", "MOVE_COMMIT");
  formData.append("payload", JSON.stringify(payload));
  fetch.updateFetchParams({ body: formData });

  fetch.rawFetch().then((response) => {
    if (!response.ok) {
      throw response;
    }
    console.log("Report sucessfully moved");
    location.reload();
    return null;
  });
};

function MoveCommitForm({ commitId, trackerId }) {
  let [toRef, setToRef] = useState("");
  return (
    <div>
      <input className="form-control" value={toRef} onChange={(e) => setToRef(e.target.value)} />
      <p>
        This will move the this report to a different Reporting Package - possibly in a different project or a place you
        don't have access to. Please ensure you would really like to do this.
      </p>
      <button className="btn btn-sm btn-primary" onClick={() => moveCommitFetch(trackerId, commitId, toRef)}>
        Move...
      </button>
    </div>
  );
}

const moveCommit = ({ commit }) => {
  let MODAL_ID = "commonReportPeriodModal001";

  setAndShowModal(MODAL_ID, {
    title: "Move Report",
    body: <MoveCommitForm commitId={commit.get("uid")} trackerId={commit.get("tracker")} />,
  });
};

const reportUrl = ({ commit }) => {
  let refId = commit.get("ref")?.substring(0, 8);
  let projectId = commit?.get("project")?.substring(0, 8);
  let commitId = commit.get("uid")?.substring(0, 8);
  let isStaging = commit.get("is_staging");
  let linkStr = isStaging
    ? `/project/${projectId}/wpack/${refId}/report`
    : `/project/${projectId}/wpack/${refId}/report/${commitId}/`;
  return linkStr;
};

const isApportionment = (project) => {
  return !!project?.getIn(["meta_json", "apportionment"]);
};

const lastUpdateTimeString = ({ commit }) => {
  let lastUpdate = commit.getIn(["meta_json", "lastUpdateOn"]) || commit.getInPath("created");
  return Moment(lastUpdate).format("Do MMM YY - HH:mm");
};

const lastUpdateNameString = ({ commit }) => {
  let lastUpdater = commit.getIn(["meta_json", "lastUpdateBy"]) || commit.get("creator");
  try {
    lastUpdater.get("first_name");
  } catch (e) {
    lastUpdater = commit.get("creator");
  }
  let name = `${lastUpdater.get("first_name")} ${lastUpdater.get("last_name")}`;
  if (!name.trim()) {
    name = lastUpdater.get("email");
  }
  return name;
};

const getUserName = (obj) => {
  if (!obj) {
    return "";
  }
  try {
    let name = `${obj.get("first_name")} ${obj.get("last_name")}`;
    if (!name.trim()) {
      name = obj.get("email");
    }
    return name;
  } catch (e) {
    return "";
  }
};

function ReportHistory({ showHistory, commit, projectReportingPeriods }) {
  if (!showHistory) return null;
  let history = commit.getIn(["meta_json", "history"]) || Im.List();

  // Get the titles of this sub-table
  const titles = ["Updated By", "Update Time", "To Status", "Comment"];
  const headCols = titles.map((title, i) => (
    <th key={i} scope="col">
      {title}
    </th>
  ));

  let rows = history.reverse().map((item, i) => {
    return (
      <tr key={i}>
        <td>{getUserName(item.get("updateBy"))}</td>
        <td>{Moment(item.get("updateOn")).format("Do MMM YY - HH:mm")}</td>
        <td>{getStatus(item)}</td>
        <td>{item.get("comment")}</td>
      </tr>
    );
  });

  return (
    <tr style={{ borderBottom: "3px solid black", borderTop: "3px solid black" }}>
      <td colSpan={7}>
        <p>
          Status and comment history for report: <b>{reportingPeriodString(projectReportingPeriods, commit)}</b>
        </p>
        <table className="table table-sm">
          <thead>
            <tr>{headCols}</tr>
          </thead>
          <tbody>{rows}</tbody>
        </table>
      </td>
    </tr>
  );
}

const dropDownLinks = (props) => {
  let { commit, project, isAdmin, isApprover, crefId, showHistory, setShowHistory, setStatus, setShowForm } = props;

  let projectId = project?.get("uid");

  let links = [
    { name: "View", linkTo: reportUrl({ commit }) },
    { name: null },
    {
      name: `${showHistory ? "Hide" : "Show"} History`,
      onClick: (e) => setShowHistory(!showHistory),
    },
  ];

  if (isApprover) {
    links = links.concat([
      { name: null },
      {
        name: "Approve",
        onClick: (e) => {
          setStatus("APPROVE");
          setShowForm(true);
        },
      },
      {
        name: "Reject",
        onClick: (e) => {
          setStatus("REJECT");
          setShowForm(true);
        },
      },
    ]);
  }
  if (isApportionment(project)) {
    let reportingPeriodId = commit.getIn(["reporting_period", "uid"]);
    links = links.concat([
      {
        name: "Apportion to My Clients",
        linkTo: `/project/${projectId}/wpack/${crefId}/apportionment/${reportingPeriodId}`,
      },
    ]);
  }
  if (isAdmin) {
    links = links.concat([
      {
        name: "Edit Reporting Period",
        onClick: () => {
          editCommitReportingPeriod(props);
        },
      },
      {
        name: "Move Report",
        onClick: () => {
          moveCommit(props);
        },
      },
    ]);
  }
  return links;
};

export default function CommitTableRow(props) {
  let [showHistory, setShowHistory] = useState(false);
  let [showForm, setShowForm] = useState(false);
  let [status, setStatus] = useState(false);
  let [comment, setComment] = useState("");
  let { isAdmin, isApprover } = useProjectPermissions();

  let { projectReportingPeriods, commit, commits, hideNullReportingPeriod, crefId, projectId } = props;
  // Get the last updated string
  let isStaging = commit.get("is_staging");
  if (isStaging) return null;

  if (commit.get("reporting_period") == null && hideNullReportingPeriod) return null;

  let commitStatus = getCommitStatus(commit);

  return (
    <React.Fragment>
      <tr>
        <td>{reportingPeriodString(projectReportingPeriods, commit) || "undefined"}</td>
        <td>{isStaging ? "Not Submitted" : commitStatus}</td>
        <td>{lastUpdateTimeString({ commit })}</td>
        <td>{lastUpdateNameString({ commit })}</td>
        <td>{commit.get("comment")}</td>
        <td>
          <BSBtnDropdown
            links={dropDownLinks({
              commit,
              commits,
              crefId,
              showHistory,
              setShowHistory,
              isApprover: isApprover(commit),
              isAdmin,
              projectReportingPeriods,
              setShowForm,
              setStatus,
            })}
          />
        </td>
      </tr>
      {showForm ? (
        <tr>
          <td colSpan={7}>
            <ApproveForm
              comment={comment}
              setComment={setComment}
              commit={commit}
              projectReportingPeriods={projectReportingPeriods}
              showForm={showForm}
              setShowForm={setShowForm}
              status={status}
              crefId={crefId}
              projectId={projectId}
            />
          </td>
        </tr>
      ) : null}

      <ReportHistory {...props} {...{ showHistory }} />
    </React.Fragment>
  );
}

export const patchCommit = (props) => {
  let { commit, crefId, projectId, comment, status } = props;

  if (comment == "") {
    Swal.fire({
      text: "Please enter a comment before approving or rejecting this report",
    });
  }
  let fetch = new Traec.Fetch("tracker_ref_commit", "patch", {
    trackerId: commit.get("tracker"),
    refId: crefId,
    commitId: commit.get("uid"),
  });
  fetch.updateFetchParams({
    body: { comment, status },
    postSuccessHook: (data) => {
      alertSuccess({
        text: `Thank you, the reporting package status has been updated`,
        onConfirm: () => {
          location.href = `/project/${projectId.substring(0, 8)}/wpack/${crefId.substring(0, 8)}/evals`;
        },
      });
    },
  });
  fetch.dispatch();
};

export function ApproveForm({
  commit,
  crefId,
  projectReportingPeriods,
  projectId,
  showForm,
  setShowForm,
  comment,
  setComment,
  status,
  currentPeriodString = null,
}) {
  if (!showForm) return null;

  currentPeriodString = currentPeriodString
    ? currentPeriodString
    : reportingPeriodString(projectReportingPeriods, commit);
  return (
    <>
      <div>
        <p>
          Changing status to <b>{status}</b> for reporting period <b>{currentPeriodString}</b>. Please provide a
          comment.
        </p>
        <input
          className="form-control mb-2"
          type="text"
          id="comment"
          name="comment"
          value={comment}
          onChange={(e) => setComment(e.target.value)}
        />
        <button className="btn btn-sm btn-default" onClick={(e) => setShowForm(!showForm)}>
          Close
        </button>
        <button
          className="btn btn-sm btn-primary float-right"
          onClick={(e) => patchCommit({ commit, crefId, projectId, comment, status })}
        >
          Submit
        </button>
      </div>
    </>
  );
}

export const reportingPeriodString = function (projectReportingPeriods, commit) {
  if (!projectReportingPeriods) {
    return null;
  }
  let reportingPeriodId = commit.get("reporting_period");
  const reportingPeriod = projectReportingPeriods.find((rp) => rp.get("uid") === reportingPeriodId);
  return rpName(reportingPeriod);
};

export const getCommitStatus = (commit) => {
  //let commitStatus = commit.getInPath("status.name") || "Pending Approval";
  if (!commit) {
    return null;
  }
  let commitStatus = commit.getIn(["status", "name"]);
  if (!commitStatus) {
    return null;
  }
  if (commitStatus.startsWith("OK")) {
    commitStatus = "Approved";
  }
  if ("Pending Review" === commitStatus) {
    commitStatus = "Pending Data Control";
  }
  return commitStatus;
};

export const getStatus = function (item) {
  let status = item.getIn(["status", "name"]);
  if (status === "OK for Submission") {
    return "Approved";
  } else {
    return status;
  }
};
