import { getCommitStatus } from "AppSrc/project/commits/row";
import { ApproveRejectForm } from "AppSrc/project/commits/approveReject";
import { Tooltip } from "react-tippy";
import React from "react";
import { BSBtn } from "traec-react/utils/bootstrap";
import { isRequiredInPeriod, isRequiredOnFreq } from "AppSrc/project/report/reportMetricRow";

import { getChildDocumentsWithStatus, getChildMetricScores } from "./utils";

import { rpName } from "storybook-dashboard/dashboard/project/reportStatus";
import Octicon from "react-octicon";
import Im from "immutable";
import { useReportContext } from "./context";

export const Submit = (props) => {
  let { commit, isResponsible, inputValueMap, nodes, nodesByPath, getChildren, rootNode } = useReportContext();

  let {
    currentReportingPeriod,
    disableInputs,
    inputErrors,
    postCommit,
    patchCommit,
    needsRevision,
    categoryTrees,
    revalidate,
  } = props;

  let commitStatus = getCommitStatus(commit);

  // Get if all of the sections are complete
  if (commitStatus && commitStatus.startsWith("Not for")) {
    return <BSBtn text="Reporting On Hold" onClick={null} disabled={true} />;
  }

  if (!commit.get("is_staging") && !(commitStatus === "Requires Revision")) {
    let currentPeriodString = currentReportingPeriod ? rpName(currentReportingPeriod) : "";
    return <ApproveRejectForm {...{ currentPeriodString }} />;
  }

  if (disableInputs) {
    return null;
  }

  let _docStatus = getChildDocumentsWithStatus(
    rootNode?.get("_path"),
    nodesByPath,
    getChildren,
    currentReportingPeriod,
  );

  let hasAllRequired = isAllRequiredSubmitted(
    inputValueMap,
    getChildMetricScores("", nodesByPath, inputValueMap.toList(), currentReportingPeriod),
    nodes,
    currentReportingPeriod,
    _docStatus,
  );
  let sectionsWithErrors = (inputErrors || Im.Map()).toList().filter((i) => i.size);

  if (hasAllRequired !== true || sectionsWithErrors.size) {
    console.log(
      "Blocking submit",
      Im.isImmutable(hasAllRequired) ? hasAllRequired.toJS() : null,
      sectionsWithErrors.size,
    );
    let errorText = getErrorText(hasAllRequired, inputErrors, categoryTrees);
    return (
      <div className={"float-right"}>
        <Tooltip html={<div className={"text-left"}>{errorText}</div>} animateFill={false}>
          <BSBtn text="Submit report" disabled={true} noFloatRight={true} />
        </Tooltip>
        <Tooltip html={"Re-validate this form"} animateFill={false}>
          <Octicon
            name="sync"
            className="text-secondary ml-3"
            style={{ cursor: "pointer" }}
            onClick={(e) => revalidate()}
          />
        </Tooltip>
      </div>
    );
  }
  return (
    <SubmitButton
      commit={commit}
      postCommit={postCommit}
      patchCommit={patchCommit}
      needsRevision={needsRevision}
      isResponsible={isResponsible()}
    />
  );
};

export const SubmitButton = ({ postCommit, patchCommit, isResponsible, commit, needsRevision }) => {
  let method = needsRevision({ isResponsible, commit }) ? patchCommit : postCommit;
  return <BSBtn text="Submit report" onClick={method} />;
};

const requiredErrors = (required) => {
  if (!Im.isImmutable(required)) {
    return Im.List();
  }
  return required.map((baseMetricName, i) => <li key={i}>{baseMetricName}</li>);
};

const getErrorText = (required, inputErrors) => {
  // Get a mapping from issue paths to real names
  let _inputErrors = inputErrors
    ? inputErrors
        .valueSeq()
        .filter((i) => Im.isImmutable(i) && i.get("name"))
        .map((item, i) => <div key={i}>{`${item.get("name")} was given a non-number value: ${item.get("value")}`}</div>)
    : Im.List();

  let _tooltip = _inputErrors.size ? (
    <React.Fragment>
      {sectionErrors}
      {_inputErrors}
    </React.Fragment>
  ) : (
    <React.Fragment>
      <li>Please enter required data before submitting the report</li>
      {required.map((item, i) => (
        <ul key={i}>{item.getIn(["metric", "name"])}</ul>
      ))}
    </React.Fragment>
  );

  return <ul className="tooltip-list">{_tooltip}</ul>;
};

const isMetricComplete = (score, scoreValuesByBaseMetric, currentReportingPeriod) => {
  const isRequired = isRequiredInPeriod(score, currentReportingPeriod);
  if (!isRequired) return true;

  const value = scoreValuesByBaseMetric.get(score.getIn(["metric", "uid"]));
  if (value) {
    const noReport = value.getIn(["meta_json", "noReport"]);
    console.log("isMetricComplete", score?.toJS(), value?.toJS(), noReport);

    return noReport ? true : ![null, undefined].includes(value.get("value", null));
  }
  return false;
};

const metricIsFalseOrNA = (score, scoreValuesByBaseMetric) => {
  let baseMetricId = score.getIn(["metric", "uid"]) || score.get("metric");
  let value = scoreValuesByBaseMetric.getIn([baseMetricId, "value"]);
  let noReport = scoreValuesByBaseMetric.getIn([baseMetricId, "meta_json", "noReport"]);
  return noReport || !value;
};

const documentRequiredAndHasUpload = (documentStatus) => {
  if (!documentStatus) {
    return true;
  }
  let isRequired = documentStatus.getIn(["meta_json", "isRequired"]);
  if (!isRequired) {
    return true;
  }
  let hasUpload = documentStatus.getIn(["status", "current_object", "url"]) != null;
  let noReport = documentStatus.getIn(["status", "status", "name"]) == "Not for Submission";
  return hasUpload || noReport
    ? true
    : Im.fromJS({ metric: { uid: documentStatus.get("uid"), name: documentStatus.get("name") } });
};

const getParentPaths = (path) => {
  let ends = Array.from(new Array(path.length / 7), (x, i) => (i + 1) * 7);
  return ends.map((i) => path.substring(0, i)).slice(0, -1);
};

const childOfExcludedPath = (path, excludePaths) => {
  let parentPaths = Im.Set(getParentPaths(path || ""));
  return excludePaths.intersect(parentPaths).size > 0;
};

export const isAllRequiredSubmitted = (
  scoreValuesByBaseMetric,
  metricScores,
  nodes,
  currentReportingPeriod,
  documentStatuses,
) => {
  if (!scoreValuesByBaseMetric || !metricScores || !nodes) {
    return null;
  }

  // Get any scores not required in this reporting period (so we can later exclude children)
  let excludePaths = Im.Set(
    metricScores
      .toList()
      .filter((score) => (isRequiredOnFreq(score, currentReportingPeriod) || {}).dueThisReport === false)
      .map((i) => i.get("_path")),
  );

  // Get any booleans that do not have a value (so we can later exclude children)
  let scoresWithHiddenChildren = metricScores
    .toList()
    .filter((score) => score.getIn(["meta_json", "hideChildrenIfNullOrZero"]));
  let excludeHiddenChildrenIfNullOrZeroPaths = Im.Set(
    scoresWithHiddenChildren
      .filter((score) => metricIsFalseOrNA(score, scoreValuesByBaseMetric))
      .map((i) => i.get("_path")),
  );

  // Join the paths to exclude
  excludePaths = excludePaths.union(excludeHiddenChildrenIfNullOrZeroPaths);

  // Check each of the metricScores and see if they have a value if required
  const required = metricScores
    .filter((i) => {
      const required = i.get("required");
      if (!required) return false;
      else return !childOfExcludedPath(i.get("_path"), excludePaths);
    })
    .toList();

  let isRequiredAndHasValue = required.map((score) => {
    const complete = isMetricComplete(score, scoreValuesByBaseMetric, currentReportingPeriod);
    return complete ? complete : score;
  });

  isRequiredAndHasValue = isRequiredAndHasValue.filter((i) => i !== true);

  // Check each of the documents and see if they have an upload (or n/a)
  let docsRequiredAndHasUpload = (documentStatuses || Im.List())
    .filter((i) => !childOfExcludedPath(i.get("_path"), excludePaths))
    .map((documentStatus) => documentRequiredAndHasUpload(documentStatus));
  docsRequiredAndHasUpload = docsRequiredAndHasUpload.filter((i) => i !== true);

  // concatenate the required lists (metrics and documents together)
  let requiredMetricsAndDocsHaveValue = isRequiredAndHasValue.concat(docsRequiredAndHasUpload);
  return requiredMetricsAndDocsHaveValue.size ? requiredMetricsAndDocsHaveValue : true;
};
